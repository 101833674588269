@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');


*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:sans-serif;
}
body{
    overflow-x: hidden;
}
.containers{
    position: relative;
    width: 100%;
    margin-left: -12px;
}
.navigation
{
    position: fixed;
    width: 250px;
    height: 100%;
    background:#003147;
    transition: 0.5s;
    overflow:hidden;
}
.navigation.active
{
    width: 60px;
}
.navigation ul
{
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
}
.navigation ul li
{
    position: relative;
    width: 100%;
    list-style: none;
}
.navigation ul li:hover
{
    background: #03a9f4;
}
.navigation ul li:nth-child(1)
{
   margin-bottom: 20px;
}
.navigation ul li:nth-child(1):hover
{
    background: transparent;
}
.navigation ul li a
{
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color:#fff;
}
.navigation ul li a .icon
{
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.navigation ul li a .icon .fa
{
    color: #fff;
    font-size: 24px;
    margin-left: -30px;
}
.navigation ul li a .title
{
    position: relative;
    display: block;
    padding: 0 10px;
    height: 60px;
    line-height: 60px;
    white-space: nowrap;
    margin-left: -30px;
    color: #fff;
}
.main
{
    position:absolute;
    width: calc(100%-250px);
    left: 250px;
    min-height: 100vh;
    background: #f5f5f5;
    transition: 0.5s;
    right: 0px;
}
.main.active
{
    width: calc(100%-60px);
    left: 60px;
}
.main .topbar
{
    position: sticky;
    width:100%;
    background:#fff;
    height: 60px;
    padding:0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggle
{
    position: relative;
    width: 60px;
    height: 60px;
   cursor: pointer;
}
.toggle::before
{
    content: '\f0c9';
    font-family: fontAwesome;
    position:absolute;
    width: 100%;
    height: 100%;
    line-height: 60px;
    font-size: 24px;
    text-align: center;
    color: #111;
}
.search
{
    position: relative;
    width: 400px;
    margin: 0 10px;
}
.search label
{
    position: relative;
    width: 100%;
}
.search label input
{
    width: 100%;
    height: 40px;
    border-radius: 40px;
    padding: 5px 20px;
    padding-left: 35px;
    outline: none;
    border:1px solid black;
}
.search label .fa
{
    position:absolute;
    left: 15px;
    top: 13px;
}
.user
{
    position: relative;
    /* min-width: 50px; */
    width: 190px;
    height: 30px;
    /* border-radius: 50%; */
    overflow:hidden;
    cursor: pointer;
}
.user img
{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cardBox
{
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 20px;
}
.cardBox .card
{
    position: relative;
    background:#fff;
    padding: 0px;
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.cardBox .card .numbers
{
    position: relative;
    font-size: 2rem;
    font-weight: 500;
}
.cardBox .card .cardTitle
{
    color: #999;
    text-align: center;
    
}
.cardBox .card .iconBox
{
    font-size: 2.5em;
    color: #03a9f4;
    
}
/*Now make it responsive*/
@media (max-width:992px)
{
    .navigation
    {
        left:-300px;
    }
    .navigation.active
    {
        left: 0;
        width: 300px;
    }
    .main
    {
        width: 100%;
        left: 0;
    }
    .main.active
    {
        width: calc(100%-300px);
        left: 300px;
    }
    .cardBox
    {
        grid-template-columns: repeat(2,1fr);
    }
   
}

@media (max-width:480px)
{
    .cardBox
    {
        grid-template-columns: repeat(1,1fr);
    }
}

